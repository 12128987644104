// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-left-sidebar-js": () => import("./../src/pages/left-sidebar.js" /* webpackChunkName: "component---src-pages-left-sidebar-js" */),
  "component---src-pages-no-sidebar-js": () => import("./../src/pages/no-sidebar.js" /* webpackChunkName: "component---src-pages-no-sidebar-js" */),
  "component---src-pages-right-sidebar-js": () => import("./../src/pages/right-sidebar.js" /* webpackChunkName: "component---src-pages-right-sidebar-js" */)
}

